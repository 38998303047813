/* Generated by Font Squirrel (https://www.fontsquirrel.com) on September 28, 2016 */



@font-face {
    font-family: 'presa-antipixel';
    src: url('fonts/presa-webfont.eot');
    src: url('fonts/presa-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/presa-webfont.woff2') format('woff2'),
         url('fonts/presa-webfont.woff') format('woff'),
         url('fonts/presa-webfont.ttf') format('truetype'),
         url('fonts/presa-webfont.svg#presa_antipixel.com.arregular') format('svg');
    font-weight: normal;
    font-style: normal;

}