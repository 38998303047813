/* Generated by Font Squirrel (https://www.fontsquirrel.com) on September 28, 2016 */



@font-face {
    font-family: 'presa_ultralight_antipultraLt';
    src: url('presa_ultralight-webfont.eot');
    src: url('presa_ultralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('presa_ultralight-webfont.woff2') format('woff2'),
         url('presa_ultralight-webfont.woff') format('woff'),
         url('presa_ultralight-webfont.ttf') format('truetype'),
         url('presa_ultralight-webfont.svg#presa_ultralight_antipultraLt') format('svg');
    font-weight: normal;
    font-style: normal;

}