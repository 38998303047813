
.spyglass_comp {
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100vh;
  box-sizing:border-box;
  border:0px solid yellow;
  background-color:$black;
  .spyglass_circle {
    position:absolute;
    width:600px !important;
    height:600px;
    left:50%;
    top:50%;
    margin-left:-300px;
    margin-top:-300px;
    pointer-events: none;
  }
  .interactive_container {
    position:relative;
    width:1238px;
    height:769px;
    left:50%;
    margin-left:-619px;
    top:50%;
    margin-top:-400px;
    background-image:url(../images/Hardus-maskingbg.png);
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    border:0px solid yellow;
    #clipImage {
      overflow:visible;
      clip-path:url(#clipPath);
    }
    svg {
      enable-background:new 0 0 1238 769;
    }
    #clipPath {
      overflow:visible;
    }
    .artInfoCont {
      position:absolute;
      left:0px;
      top:0px;
      width:800px;
      height:600px;
      border:0px solid yellow;
      pointer-events: none;
      .infoText {
        font-family:'MuseoSans100',Arial,Helvetica;
        color:$gold;
        font-weight:normal;
        pointer-events: none;
        font-size:20px;
        position:absolute;
        opacity:0;
        &.info-title {
          left:0;
          top:0;
        }
        &.info-medium {
          right:0;
          top:0;
        }
        &.info-size {
          left:0;
          bottom:0;
        }
      }
    }
    .hotspot_box {
      position:absolute;
      background-color:transparent;
      cursor:pointer;
      &.hotspot1 {
        width:331px;
        height:218px;
        left:249px;
        top:39px;
      }
      &.hotspot2 {
        width:274px;
        height:168px;
        left:32px;
        top:242px;
      }
      &.hotspot3 {
        width:357px;
        height:230px;
        left:126px;
        top:464px;
      }
      &.hotspot4 {
        width:307px;
        height:234px;
        left:513px;
        top:525px;
      }
      &.hotspot5 {
        width:466px;
        height:277px;
        left:744px;
        top:341px;
      }
      &.hotspot6 {
        width:322px;
        height:228px;
        left:787px;
        top:53px;
      }
      &.hotspot7 {
        width:558px;
        height:277px;
        left:338px;
        top:230px;
      }
    }
  }
}