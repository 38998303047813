.slide {
  &.info_page_impressed {
    bottom:0%;
    background-image:url(../images/infobg-impressed.jpg);
    background-position:center bottom;
    background-color:$black;
    .corner-dot-device {
      @extend %top-left;
    }
    h2 {
      display:none;
    }

    .posterCont {
      position:absolute;
      top:0;
      width:28%;
      .bid_btncont {
        position:relative;
        .makeofferBtnCont {
          position:absolute;
          left:50%;
          top:30px;
          margin-left:-126px;
          .btn-caption {
            text-align:center;
          }
        }
      }
      -webkit-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.5);
      -moz-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.5);
      box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.5);

      &.poster1 {
          left:5%;    
      }
      &.poster2 {
          left:50%;
          margin-left:-14%;    
      }
      &.poster3 {
          right:5%;
      }
      .suspenders {
        position:relative;
        border:1px solid $gold;
        border-width:0 1px 0 1px;
        height:130px;
        width:100%;
        box-sizing: border-box;
        p {
          position:absolute;
          top:50px;
          left:40%;
          margin-top:-5px;
          color:$gold;
          font-size:24px;
          text-align:left;
          font-weight:normal;
          font-family:'MuseoSans100',Arial,Helvetica;
        }
        .line-graphic {
          border:1px solid $gold;
          border-width:1px 0 0 1px;
          position:absolute;
          left:20%;
          top:45%;
          width:19%;
          height:40px;
        }
      }

      .impressed-poster-img {
        width:100%;
        height:auto;
        cursor:pointer;
      }

    }
  }  
}
