
%top-right {
  top:3.18%;
  right:2.18%;
}
%top-left {
  top:3.18%;
  left:2.18%;  
}
%bot-right {
  bottom:115px;
  right:2.18%;
}
%bot-left {
  bottom:115px;
  left:2.18%;
}

@function calc-width-perc($pixels) {
  $perc : ($pixels/1920)*100;
  @return $perc + "%";
}
@function calc-height-perc($pixels) {
  $perc : ($pixels/900)*100;
  @return $perc + "%";
}

.close-cont-mask {
  position:absolute;
  left:0%;
  top:0%;
  background: transparent;
  cursor:crosshair;
  width:100%;
  height:100vh;
  border:0px solid yellow;
  cursor: $closeContCursor;
  display: none;
}

.slide, .component_container {

  &.contact-list-page{
    overflow: scroll;
    padding: 0 0 100px 0;
  }
  
  width: 100%;
  height: 100vh;

  overflow:hidden;
  margin:0;
  border:solid 0px yellow;
  background-position:center center;
  background-size:cover;
  background-repeat: no-repeat;

  h2 {
    font-family:'presa-antipixel',Arial,Helvetica;
    color:$gold;
    text-align:left;
    font-weight:normal;
    max-width: 400px;

    font-size:36px;
    line-height:1em;
    margin:0 0 0 0;
    position:absolute;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  }

  .introTextHolder{
    position: absolute;
    color: white;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    top: 0;
    z-index: 999;

    p{
      font-family:'presa-antipixel',Arial,Helvetica;
      color:$gold;
      width: 60%;
      font-size: 36px;
    }    
  }

  .als{
    font-family: 'presa-antipixel',Arial,Helvetica !important;
    font-size: 25px !important;
    color: $gold!important;
    @media screen and ( max-height: 700px ){
      font-size: 18px!important;
    }
    @media screen and ( max-width: 1400px ){
      font-size: 18px!important;
    }
  }

  .logoHolder{
    width:226px;
    position: absolute;
    top: 40px;
    left: 40px;
  }


  .corner-dot-device {
    position:absolute;
    width:7.917%!important;
    height:auto;
    &.top-right {
      top:2.18%;
      right:2.18%;
    }
  }

  .grid-layout {
    position:relative;
    width:100%;
    box-sizing: border-box;
    border:0px solid yellow;
    .grid-box {
      position:relative;
      margin:0 0 0 0;
      height:100%;
      background-repeat: no-repeat;
      background-position:center top;
      background-size:cover;
      &.left-box {
        float:left;
      }
      &.right-box {
        float:right;
      }
    }
  }

  .fullscreen-bg__video {
      position: absolute;
      top: 0%;
      left: 0%;
      width: 100%;

      margin-top:0%;
      margin-left:0%;
      height: auto;
      max-width:100%;
  }


//  Category slides starts here --------------------------------

  //  INTRO PAGE
  &.page_intro{
    /*background-image:url(../images/intro_bg.png);*/
    background: black;
    h2 {
      display:none;
    }
    .corner-dot-device {
      /*@extend %top-right;*/
      display: none;
    }
    .makeofferBtnCont, .infoPageBtnCont {
      display:none;
    }
  }


  //  ANTON EN LIONEL SMIT
  &.cat_page_anton{
    background-image:none; background-color:$black;
    .corner-dot-device {
      @extend %top-left;
    }
    h2 {
      display:none;
    }
    .grid-top {
      height:49.66666%;
      margin-bottom:0.4%;
      .box1 {
        width:57%;
        background-image:url(../images/cat1-grid01.jpg);
      }
      .box2 {
        width:43%;
        background-image:none;
        background-color:$black;
        overflow:hidden;
        video {
          cursor:pointer;
          display: block;
        }
        .fullscreen-bg__video {
          position: absolute;
          top: 0%;
          left: 50%;
          width: auto;
          height:100%;
          margin-top:0%;
          margin-left:-50%;
        }
      }
    }
    .grid-bottom {
      height:49.66666%;
      .box1 {
        width:46.7%;
        background-image:url(../images/cat1-grid03.jpg);
      }
      .box2 {
        width:25.5%;
        background-image:url(../images/cat1-grid04.png);
        background: $black;
        .infoPageBtnCont {
          position:relative;
          left:50%;
          margin-left:-126px;
        }
        .btn_info {
          color:$black;
          text-align:center;
          font-family:'MuseoSans100',Arial,Helvetica;
          font-size:16px;
          padding:30px 0 0 0;
          width:80%;
          position:relative;
          left:50%;
          margin-left:-40%;
        }
        hr {
          border:1px solid $black;
          margin:20px 35px;
          border-width:1px 0 0 0;
        }
      }
      .box3 {
        width:27.8%;
        background-image:url(../images/cat1-grid05.jpg);
      }
    }
  }
  &.info_page_anton {
    top:-100%;
    background-image:url(../images/infobg-anton.jpg); background-color:$black;
    .corner-dot-device {
      @extend %top-right;
    }
    h2 {
      @extend %bot-left;
    }
    .makeofferBtnCont {
      position:absolute;
      @extend %bot-right;
    }
    .close-cont-mask {
      display:inherit;
    }
    // INFO TAGS
    .medium {
      left: unquote(calc-width-perc(334));
      top: unquote(calc-height-perc(305));
      p {
        right:20px;
        text-align:right;
      }
      .line-graphic {
        width: 97px;
        height:1px;
        border-width:1px 0 0 0;
        left:20px;
      }
    }
    .title {
      left: unquote(calc-width-perc(363));
      top: unquote(calc-height-perc(542));
      p {
        right:20px;
        text-align:right;
      }
      .line-graphic {
        width: 97px;
        height:1px;
        border-width:1px 0 0 0;
        left:20px;
      }
    }
    .size {
      right: unquote(calc-width-perc(422));
      top: unquote(calc-height-perc(437));
      p {
        left:20px;
        text-align:left;
      }
      .line-graphic {
        width: 32px;
        height: 634px;
        border-width:1px 1px 1px 0;
        top:-317px;
        right:20px;
      }
    }
  }


  //  STREETWIRES
  &.cat_page_streetwires {
    background-image:url(../images/cat2_bg_1.png);
    .corner-dot-device {
      @extend %top-right;
    }
    h2 {
      display:none;
    }
    .infoPageBtnCont {
      position:absolute;
      @extend %bot-right;
    }
  }
  &.info_page_streetwires {
    bottom:-100%;
    background-image:url(../images/cat2_bg_2.png); background-color:$black;
    .corner-dot-device {
      @extend %top-left;
    }
    h2 {
      @extend %top-left;
    }
    .makeofferBtnCont {
      position:absolute;
      @extend %bot-right;
    }
    .close-cont-mask {
      display:inherit;
    }
    // INFO TAGS
    .medium {
      left: unquote(calc-width-perc(389));
      top: unquote(calc-height-perc(456));
      p {
        right:20px;
        text-align:right;
      }
      .line-graphic {
        width: 97px;
        height:1px;
        border-width:1px 0 0 0;
        left:20px;
      }
    }
    .title {
      right: unquote(calc-width-perc(620));
      top: unquote(calc-height-perc(100));
      p {
        left:20px;
        text-align:left;
      }
      .line-graphic {
        width: 143px;
        height:50px;
        border-width:1px 0 0 1px;
        right:20px;
        top:0px;
      }
    }
    .size {
      right: unquote(calc-width-perc(381));
      top: unquote(calc-height-perc(480));
      p {
        left:20px;
        text-align:left;
      }
      .line-graphic {
        width: 32px;
        height: 634px;
        border-width:1px 1px 1px 0;
        top:-317px;
        right:20px;
      }
    }
  }

  //  JACO - BITTEREINDER
  &.cat_page_jaco {
    background-image:url(../images/catbg-jacovandermerwe.jpg);
    .corner-dot-device {
      @extend %top-right;
    }
    h2 {
      @extend %top-left;
    }
    .infoPageBtnCont {
      position:absolute;
      @extend %bot-right;
    }
  }
  &.info_page_jaco {
    bottom:0%;
    background-image:url(../images/infobg-jaco.jpg); background-color:$black;
    .corner-dot-device {
      @extend %top-right;
    }
    h2 {
      @extend %top-left;
    }
    .makeofferBtnCont {
      position:absolute;
      @extend %bot-right;
    }
    .close-cont-mask {
      display:block;
    }
    /*info tags*/
    .medium {
      left: unquote(calc-width-perc(500));
      bottom: unquote(calc-height-perc(150));
      p {
        right:20px;
        text-align:right;
        width: 200px;
      }
      .line-graphic {
        width: 97px;
        height:1px;
        border-width:1px 0 0 0;
        left:20px;
      }
    }
    .title {
      right: unquote(calc-width-perc(1500));
      top: unquote(calc-height-perc(100));
      p {
        width: 300px;
        left:0px;
        top: -33px;
        text-align:left;
      }
      .line-graphic {
        width: 80px;
        height:50px;
        border-width:1px 0 0px 1px;
        right:20px;
        top:-25px;
      }
    }
    .size {
      right: unquote(calc-width-perc(770));
      /*top: unquote(calc-height-perc(360));*/
      top: 47%;
      p {
        left:10px;
        text-align:left;
      }
      .line-graphic {
        width: 32px;
        height: 634px;
        border-width:1px 1px 1px 0;
        top:-317px;
        right:20px;
      }
    }
    
  }

  &.info_page_impressed{

    .close-cont-mask {
      display:inherit;
    }
  }

  // IMPRESSED
  &.cat_page_impressed {
    background-image:url(../images/cat4_bg_1.png);
    .corner-dot-device {
      @extend %top-right;
    }
    h2 {
      @extend %top-left;
    }
    .infoPageBtnCont {
      position:absolute;
      @extend %bot-right;
    }

    .introTextHolder p{
      margin: 250px 0 0 0;
    }
  }


  // HARDUS KOEKEMOER
  &.spyglass_comp {
    h2 {
       @extend %top-left;
    }
    .corner-dot-device {
      @extend %top-right;
    }
    .makeofferBtnCont {
      position:absolute;
      @extend %bot-right;
    }
    
  }


  // POKO PRODUCTIONS
  &.cat_page_poko {
    /*background-image:url(../images/catbg-poko.jpg);*/
    background: $black;
    .corner-dot-device {
      @extend %top-right;
    }
    h2 {
      @extend %top-left;
    }
    .infoPageBtnCont {
      position:absolute;
      @extend %bot-right;
    }
  }
  &.info_page_poko {
    bottom:0%;
    background-image:url(../images/catbg-poko.jpg); background-color:$black;
    .corner-dot-device {
      @extend %top-left;
    }
    h2 {
      display:none;
    }
    .makeofferBtnCont {
      position:absolute;
      @extend %bot-right;
    }
    .close-cont-mask {
      display:inherit;
    }
    video[poster] {
      background: transparent url(../images/infobg-karensteenkamp.jpg) 50% 50% / cover no-repeat ;
    }
    /*info tags*/
    .medium {
      left: unquote(calc-width-perc(230));
      top: unquote(calc-height-perc(535));
      p {
        right:20px;
        text-align:right;
      }
      .line-graphic {
        width: 97px;
        height:1px;
        border-width:1px 0 0 0;
        left:20px;
      }
    }
    .title {
      right: unquote(calc-width-perc(1100));
      top: unquote(calc-height-perc(100));
      p {
        width: 200px;
        left:0px;
        top: -36px;
        text-align:left;
        color:black;
      }
      .line-graphic {
        border-color: black;
        width: 80px;
        height:50px;
        border-width:1px 0 0px 1px;
        right:20px;
        top:-25px;
      }
    }
    .size {
      right: unquote(calc-width-perc(900));
      /*top: unquote(calc-height-perc(360));*/
      top: 30%;
      p {
        left:20px;
        text-align:left;
        color: black;
      }
      .line-graphic {
        width: 32px;
        height: 304px;
        border-width:1px 1px 1px 0;
        top:-317px;
        right:20px;
        display: none;
      }
    }
  }


  //  ROBOBEAST
  &.cat_page_robobeast {
    /*background-image:url(../images/catbg-robobeast.jpg);*/
    background: $black;
    .corner-dot-device {
      @extend %top-right;
    }
    h2 {
      @extend %top-left;
    }
    .infoPageBtnCont {
      position:absolute;
      @extend %bot-right;
    }
  }
  &.info_page_robobeast {
    bottom:0%;
    background-image:url(../images/catbg-robobeast.jpg); background-color:$black;
    .corner-dot-device {
      @extend %top-right;
    }
    h2 {
      display:none;
    }
    .makeofferBtnCont {
      position:absolute;
      @extend %bot-right;
    }
    .close-cont-mask {
      display:inherit;
    }
    video[poster] {
      background: transparent url(../images/infobg-karensteenkamp.jpg) 50% 50% / cover no-repeat ;
    }
    /*info tags*/
    .medium {
      left: unquote(calc-width-perc(520));
      top: unquote(calc-height-perc(535));
      p {
        right:20px;
        text-align:right;
        color: black;
      }
      .line-graphic {
        border-color: black;
        width: 97px;
        height:1px;
        border-width:1px 0 0 0;
        left:20px;
      }
    }
    .title {
      right: unquote(calc-width-perc(800));
      top: unquote(calc-height-perc(100));
      p {
        width: 120px;
        left:0px;
        top: -36px;
        text-align:left;
        /*color:black;*/
      }
      .line-graphic {
        /*border-color: black;*/
        width: 80px;
        height:50px;
        border-width:1px 0 0px 1px;
        right:20px;
        top:-25px;
      }
    }
    .size {
      right: unquote(calc-width-perc(600));
      /*top: unquote(calc-height-perc(360));*/
      top: 30%;
      p {
        left:20px;
        text-align:left;
        color: black;
      }
      .line-graphic {
        width: 32px;
        height: 304px;
        border-width:1px 1px 1px 0;
        top:-317px;
        right:20px;
        display: none;
      }
    }

  }

  


  // KAREN STEENKAMP
  &.cat_page_karen {
    /*background-image:url(../images/catbg-karensteenkamp.jpg);*/
    background: $black;
    .corner-dot-device {
      @extend %top-right;
    }
    h2 {
      display:none;
    }
    .infoPageBtnCont {
      position:absolute;
      @extend %bot-right;
    }
    // INFO TAGS
    .medium {
      left: unquote(calc-width-perc(188));
      top: unquote(calc-height-perc(535));
      p {
        right:20px;
        text-align:right;
      }
      .line-graphic {
        width: 97px;
        height:1px;
        border-width:1px 0 0 0;
        left:20px;
      }
    }
    .title {
      right: unquote(calc-width-perc(849));
      top: unquote(calc-height-perc(750));
      p {
        left:20px;
        text-align:left;
      }
      .line-graphic {
        width: 80px;
        height:50px;
        border-width:0 0 1px 1px;
        right:20px;
        top:-25px;
      }
    }
    .size {
      right: unquote(calc-width-perc(284));
      top: unquote(calc-height-perc(360));
      p {
        left:20px;
        text-align:left;
      }
      .line-graphic {
        width: 32px;
        height: 634px;
        border-width:1px 1px 1px 0;
        top:-317px;
        right:20px;
      }
    }
  }
  &.info_page_karen {
    bottom:0%;
    background-image:url(../images/catbg-karensteenkamp.jpg); background-color:$black;
    .corner-dot-device {
      @extend %top-right;
    }
    h2 {
      display:none;
    }
    .makeofferBtnCont {
      position:absolute;
      @extend %bot-right;
    }
    .close-cont-mask {
      display:inherit;
    }
    video[poster] {
      background: transparent url(../images/infobg-karensteenkamp.jpg) 50% 50% / cover no-repeat ;
    }
    .medium {
      left: unquote(calc-width-perc(188));
      top: unquote(calc-height-perc(535));
      p {
        right:20px;
        text-align:right;
      }
      .line-graphic {
        width: 97px;
        height:1px;
        border-width:1px 0 0 0;
        left:20px;
      }
    }
    .title {
      right: unquote(calc-width-perc(849));
      top: unquote(calc-height-perc(750));
      p {
        left:20px;
        text-align:left;
      }
      .line-graphic {
        width: 80px;
        height:50px;
        border-width:0 0 1px 1px;
        right:20px;
        top:-25px;
      }
    }
    .size {
      right: unquote(calc-width-perc(284));
      top: unquote(calc-height-perc(360));
      p {
        left:20px;
        text-align:left;
      }
      .line-graphic {
        width: 32px;
        height: 634px;
        border-width:1px 1px 1px 0;
        top:-317px;
        right:20px;
      }
    }

  }


  // CAPITAL CRAFT
  &.cat_page_capitalcraft {
    /*background-image:url(../images/catbg-capitalcraft.jpg);*/
    background: $black;
    .corner-dot-device {
      @extend %top-right;
    }
    h2 {
      @extend %top-left;
    }
    .infoPageBtnCont {
      position:absolute;
      @extend %bot-right;
    }
  }
  &.info_page_capitalcraft {
    bottom:0%;
    background-image:url(../images/catbg-capitalcraft.jpg); background-color:$black;
    .corner-dot-device {
      @extend %top-right;
    }
    h2 {
      display:none;
    }
    .makeofferBtnCont {
      position:absolute;
      @extend %bot-right;
    }
    .close-cont-mask {
      display:inherit;
    }
    /*info tags*/
    .medium {
      left: unquote(calc-width-perc(200));
      top: unquote(calc-height-perc(535));
      p {
        right:20px;
        text-align:right;
      }
      .line-graphic {
        width: 97px;
        height:1px;
        border-width:1px 0 0 0;
        left:20px;
      }
    }
    .title {
      right: unquote(calc-width-perc(800));
      top: unquote(calc-height-perc(50));
      p {
        width: 200px;
        left:0px;
        top: -36px;
        text-align:left;
        /*color:black;*/
      }
      .line-graphic {
        /*border-color: black;*/
        width: 80px;
        height:50px;
        border-width:1px 0 0px 1px;
        right:20px;
        top:-25px;
      }
    }
    .size {
      right: unquote(calc-width-perc(600));
      /*top: unquote(calc-height-perc(360));*/
      top: 30%;
      p {
        left:20px;
        text-align:left;
      }
      .line-graphic {
        width: 32px;
        height: 304px;
        border-width:1px 1px 1px 0;
        top:-317px;
        right:20px;
        display: none;
      }
    }
  }


  // CAPITAL CRAFT
  &.cat_page_retief {
    background-image:url(../images/catbg-retief.jpg);
    .corner-dot-device {
      display:none;
    }
    h2 {
      display:none;
    }
    .infoPageBtnCont {
      display:none;
    }
  }




  // TEMPORARY PARRALAX SCROLLING COMPONENT
  &.parralax_comp {
    background-image:url(../images/Hardus-maskingbg.png);
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    border:0px solid yellow;
    h2 {
       @extend %top-left;
    }
    .corner-dot-device {
      @extend %bot-left;
    }
    .makeofferBtnCont {
      position:absolute;
      @extend %bot-right;
    }
    .parra-layer {
      position:absolute;
      display:block;
      left:50%;
      top:50%;
      pointer-events: none;
      &.parraS00 {
        margin-left:-350px;
        margin-top:-100px;
        width:480px;
      }
      &.parraS01 {
        margin-left:0px;
        margin-top:-300px;
        width:480px;
      }
      &.parraS02 {
        margin-left:-240px;
        margin-top:-500px;
        width:480px;
      }
      &.parraB01 {
        margin-left:-893px;
        margin-top:15%;
        width:980px;
      }
      &.parraB02 {
        margin-left:-400px;
        margin-top:10%;
        width:1200px;
      }
    }
  }

//  Temporary blank category and info page styles --------------------

  &.cat_page_blank {
    background-image:none; background-color:$black;
    .corner-dot-device {
      @extend %top-right;
    }
    h2 {
      @extend %top-left;
    }
    .infoPageBtnCont {
      position:absolute;
      @extend %bot-right;
    }
  }

  &.info_page_blank {
    bottom:0%;
    background-image:none; background-color:$black;
    .corner-dot-device {
      @extend %bot-left;
    }
    h2 {
      @extend %top-left;
      font-size:51px;
      line-height:1em;
      margin:0 0 0 0;
      width:50%;
      position:absolute;
      margin-top:0;
      margin-left:0;
    }
    .makeofferBtnCont {
      position:absolute;
      left:auto;
      width:252px;
      @extend %bot-right;
      .infoPageBtnCont {
        display:none;
      }
    }
    .close-cont-mask {
      display:inherit;
    }
  }


  &.contact-list-page {
    .main_container {
      position:relative;
      width:86%;
      left:50%;
      margin-left:-43%;
      padding-top:70px;
      .contact_cont {
        width:25%;
        float:left;
        padding-bottom:50px;
        h2 {
          position:relative;
          display:block;
          font-size:27px;
          color:$gold;
          text-align:center;
          padding-bottom:10px;
          margin:0 0 0 0;
        }
        p {
          position:relative;
          display:block;
          font-family:'MuseoSans100',Arial,Helvetica;
          color:$gold;
          text-align:center;
          font-size:14px;
          margin:0 0 0 0;
        }
      }
    }
  }


  



  &.page_03{
    background-image:url(../images/cat3_bg_1.png);
  }
  &.page_04{
    background-image:url(../images/cat4_bg_1.png);
  }
  &.page_05{
    background-image:url(../images/cat5_bg_1.png);
  }
  &.page_06{
  }
}



.reveal{
    width: 100%;
    height: 100vh;
    position: relative;

    .revealBlock{
/*      min-width: 150px;
      min-height: 150px;*/
      float: left;
      background: $black;
    }
  }