/* Generated by Font Squirrel (https://www.fontsquirrel.com) on September 28, 2016 */

@font-face {
	font-family: 'MuseoSans100';
	src: url('fonts/MUSEOSANS-100.eot');
	src: url('fonts/MUSEOSANS-100.eot?#iefix') format('embedded-opentype'),
		 url('fonts/MUSEOSANS-100.woff') format('woff'),
		 url('fonts/MUSEOSANS-100.ttf') format('truetype');
		 
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'MuseoSans100italic';
	src: url('fonts/MUSEOSANS-100ITALIC.eot');
	src: url('fonts/MUSEOSANS-100ITALIC.eot?#iefix') format('embedded-opentype'),
		 url('fonts/MUSEOSANS-100ITALIC.woff') format('woff'),
		 url('fonts/MUSEOSANS-100ITALIC.ttf') format('truetype');
		 
	font-weight: normal;
	font-style: normal;
}