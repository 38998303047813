/* ==========================================================================
   Imports/Includes
   ========================================================================== */
@import 'fonts';
@import '_variables';


// site-relevant styles
body,html{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Cabin', Sans-serif;
    background-color:#000000;
}
div,td,button,section {
  box-sizing: border-box;
  margin:0 0 0 0;
}
p,li,h1,h2,h3,h4,h5 {
  margin:0 0 0 0;
  padding:0 0 0 0;
}
a {
  outline:none;
  text-decoration:none;
  border:none;
  outline: 0;
  outline:none;
}
a:focus { background-color:#393939; color:#FFFFFF; }

.ball{
	width: 20px;
	height: 20px;
	position: absolute;
	border-radius: 10px;
	background: blue;
	left: 0;
	top: 0;
}

.home, #react-root{
  width: 100%;
  min-height: 100%;
}
.home {
  position:relative;
  float:left;
  overflow:hidden;
  height:100vh;
}

section{
  width: 100%;
  height: 100%;

  & #intro{
    background: blue;
  }
  & #about{
    background: green;
  }
  & #intro{
    background: red;
  }
}



.component-top {
  position:absolute;
  top:-100%;
  left:0%;
  width:100%;
}

.anim-btn {
  width:252px;
  height:70px;
  position:relative;
  cursor:pointer;
  p {
    font-family:'MuseoSans100',Arial,Helvetica;
    color:$gold;
    text-align:center;
    font-weight:bold;
    line-height:70px;
    pointer-events: none;
    font-size:24px;
  }
  .sideLineRight {
    position:absolute;
    top:0%;
    right:0%;
    width:8px;
    height:50%;
    background-color:$gold;
    pointer-events: none;
  }
  .botLineLeft {
    position:absolute;
    bottom:0%;
    left:0%;
    width:25%;
    height:8px;
    background-color:$gold;
    pointer-events: none;
  }
  &.gold {
    background-color:$goldBG;
    border:2px solid $gold;
  }
}
.makeofferBtnCont, .infoPageBtnCont {
  width:252px;
  .btn-caption {
    font-family:'MuseoSans100',Arial,Helvetica;
    color:$gold;
    text-align:left;
    font-weight:normal;
    line-height:1;
    pointer-events: none;
    font-size:20px;
    padding-bottom:5px;
  }
}

.clear { clear:both; }

@import '../../node_modules/owl.carousel/src/scss/owl.carousel';

.owl-carousel {
  z-index: 0;
  position:absolute;
  top:0%;
  left:0%;
  width:100%;
  height:100vh;
  min-height:100%;
  .slide {
  }
}

.preloader{
  width: 100%;
  position: absolute;
  top: 30%;
  color: $white;
  text-align: center;
  font-size: 100px;
}

.sound-control{
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 30px;
  right: 10px;
  opacity: 0.6;
  cursor: pointer;
  z-index: 100;

  .sound-icon{
    fill: $gold;
  }
}

.clear {
  clear:both;
}
.mobile-holder{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.mobile{
    font-family:'MuseoSans100',Arial,Helvetica;
    color:$gold;
    font-size: 18px;
    text-align: center;
    width: 70%;
}

.contact_cont{

  a{
    color: $gold;
  }
}

.explainer{
  position: absolute;
  z-index: 99;
  top: 110%;
  left: 50%;
  margin-left: -75px;
}

@import "./slides";
@import "./video-component";
@import "./circle-dots";
@import "./cat-navigation";
@import "./spyglass-comp";
@import "./form";
@import "./impressed-infopage";
@import "./artwork-info-tags";
