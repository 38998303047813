/*/// Mixin to place items on a circle
/// @author Hugo Giraudel
/// @author Ana Tudor
/// @param {Integer} $item-count - Number of items on the circle
/// @param {Length} $circle-size - Large circle size
/// @param {Length} $item-size - Single item size*/

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width:  $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%; 
  list-style: none;       
  
  > * {
    display: block;
    position: absolute;
    top:  50%;
    left: 50%;
    width:  $item-size;
    height: $item-size;
    margin: -($item-size / 2);
  
    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: 
          rotate($rot * 1deg) 
          translate($circle-size / 2) 
          rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}

.dots-container {
  @include on-circle($item-count: 36, $circle-size: 80em, $item-size: 16px); 
  margin: -10em auto 0;
  border: solid 0px tomato;
  
  img { 
    display: block; 
    max-width: 100%; 
  }
}


.inside_circle {
  @include on-circle($item-count: 36, $circle-size: 72em, $item-size: 16px); 
  margin: -6em auto 0;
  border: solid 0px tomato;
  img { 
    display: block; 
    max-width: 100%; 
  }
}

#dotCircle, #insideCircle {
  position:absolute;
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  opacity:0;
  
  margin:0 0 0 0;
  border:solid 0px tomato;
  pointer-events: none;

  opacity:0;
  z-index: 2;
}

.circleDotsWrapper {
  position:absolute;
  left:0;
  top:0;
  overflow:hidden;
  pointer-events: none;
  height:100vh;
  width:100%;
  max-height:100vh;
}