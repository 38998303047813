.form-holder{
  width: 100%;
  height: 100vh;
  position: absolute; 
  pointer-events: none;
  background: rgba(0,0,0,0.7);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .form{
    pointer-events: all;
    position: relative;
    width: 408px;
    /*margin: 200px auto;*/
    /*margin: 0 auto;*/

    .reserveprice{
      font-family: 'presa-antipixel';
      font-size: 30px;
      margin: 0 0 10px 0;
      color: $gold;
    }

    input{
      border: none;
      width: 408px;
      float: left;
      clear: left;
      height: 40px;
      background: url('../images/input-bg.png') no-repeat;
      font-family: 'MuseoSans100',Arial,Helvetica;
      font-size: 16px;
      line-height: 40px;
      padding: 0 10px;
      color: $gold;
    }

    label{
      font-family: 'presa-antipixel';
      font-size: 20px;
      color: $gold;
      width: 100%;
      display: block;
      float: left;
      margin: 4px 0 5px 0;
    }

    .submit{
      /*position: absolute;
      right: 0;
      top:500px;*/
      float: right;
      margin: 23px 0 0 0;
    }

  }
  .disclaimer {
    float: left;
    margin: 30px 0 0 0;
    font-family: 'MuseoSans100',Arial,Helvetica;
    font-size: 11px;
    color: $gold;
  }

  .close-cont-mask{
    pointer-events: all;
    display: block;
  }
}