

.tag-position {
  position:absolute;
  pointer-events: none;
  width:1px;
  height:1px;
  border:0px solid yellow;
  overflow:visible;
  .tag-relative {
    position:relative;
    overflow:visible;
  }
  p {
    position:absolute;
    top:-12px;
    color:$gold;
    font-size:18px;
    text-align:left;
    font-weight:normal;
    font-family:'MuseoSans100',Arial,Helvetica;
  }
  .line-graphic {
    border-color:$gold;
    border-style:solid;
    position:absolute;
    top:0%;
  }
}