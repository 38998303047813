
.navComponent {
  background-color:#191614;
  position:absolute;
  bottom:0%;
  left:0%;
  width:100%;
  box-sizing: border-box;
  border-top-width:2px;
  border-top-color:$gold;
  border-style:solid;
  height:71px;
  z-index: 3;
  .navBtns_con {
    position:relative;
    width:50%;
    height:9px;
    left:50%;
    margin-left:-25%;

    .navBtn {
      position:relative;
      float:left;
      width:7.690%;
      background-color:#191614;
      height:100%;
      cursor:pointer;
      border:1px solid $goldBG;
      &:hover {
        background-color:$gold;
        cursor:pointer;
      }
      &.selected {
        background-color:$gold;
      }
    }
  }
  .navTitle {
      font-family:'presa-antipixel','MuseoSans100',Arial,Helvetica;
      color:$gold;
      text-align:center;
      font-weight:bold;
      line-height:1;
      font-size:16px;
      text-transform: uppercase;
      padding-top:15px;
      cursor:pointer;
      border:0px solid yellow;
  }
}

.navPage {
  position:absolute;
  left:0%;
  bottom:0%;
  width:100%;
  height:1px;
  background-color:$blackBG;
  overflow:hidden;
  background-image:url(../images/intro_bg.png);
  background-position:center center;
  background-size:cover;
  background-repeat: no-repeat;
  h2 {
    font-family:'presa-antipixel',Arial,Helvetica;
    color:$gold;
    text-align:center;
    font-weight:normal;

    font-size:51px;
    line-height:1em;

    position:absolute;
    width:40%;
    left:50%;
    margin-left:-20%;
    height:52px;
    top:50%;
    margin-top:-26px;
  }
  .close-cont-mask {
    border:0px solid yellow;
    display:block;
  }
}