
.video-component {
  position:absolute;
  top:-100%;
  left:0%;
  width:100%;
  background-image:none;
  background-color:$black;
  video {
    cursor: $closeContCursor;
    display: block;
  }
  
  .fullscreen-bg {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
  }
  .fullscreen-bg__video {
      position: absolute;
      top: 0%;
      left: 50%;
      width: 100%;

      margin-top:0%;
      margin-left:-50%;
      height: auto;
      max-height:100%;
      max-width:100%;
  }
}